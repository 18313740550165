* {
  margin: 0 !important;
  color: white;
  overflow-x: hidden;
}

.profile-container {
  z-index: 1;
  height: 100vh !important;
  width: 100%;
  position: relative;
}

.profile-parent{
  justify-content: center;
  display: flex;
}

.cols {
  position:absolute;
  top: 35vh;
  color: aliceblue;
  width: max(60vw);
  height: 53vh;
  z-index: 0;
}

.cols h1 {
  font-size: clamp(42px, 12vw, 80px);
  padding-bottom: 10px;
}
.cols p {
  color: rgb(243, 236, 236);
}

.cols h4 {
  width: 100vw;
  color: rgb(223, 213, 215);
}

.contact-btn{
 margin: 2em 0 0 !important;
 word-wrap: normal!important;
}

.cols .btn {
  
}
.cols .btn:hover {

}

.cols .btn::after {
  
}
.cols .btn:hover::after {
  
}



.contact-popup{
  z-index: 1000;
}

.overlay {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
}

.popup {
  padding: 20px;
  background: #fff !important;
  border-radius: 8px;
  width: clamp(40%, 50vw, 10em);
  position: relative;
  top: 25vh;
  height: fit-content;
  transition: opacity 5s ease-in-out;
  overflow: hidden;
}

.popup h2 {
  margin-top: 0;
  padding: 0 5px 5px;
  color: #333;
  overflow-y: hidden;
}
.popup .close {
  position: absolute;
  top: 0px;
  right: 24px;
  transition-timing-function: ease-in;
  transition: 100ms;
  font-size: 40px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}
.popup .close:hover {
  cursor: pointer;
  color: pink;
}
.popup .content {
  overflow: hidden;
  padding: 5px;
}

@media screen and (max-width: 700px) {
  .popup {
    width: 70%;
  }
}
.center {
  margin: auto !important;
}

.label {
  color: black;
}

#contact form,
input,
textarea {
  width: 100%;
}
textarea {
  height: 7em;
  border: 2px solid grey;
  resize: none;
  color: black;
  background: #eee;
  padding: 3px 10px;
}
input:focus,
textarea:focus,
select:focus {
  outline: none;
}

form{
  overflow: -moz-hidden-unscrollable;
  overflow: hidden;
}

input {
  padding: 3px 10px;
  border: 2px;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: solid;
  background-color: #eee;
  color: black;
}

#contactSubmit {
  float: right;
  position: relative;
  top: .5em;
}

.text-gradient {
	background: linear-gradient(-45deg, #fd688b, #f9ab51, #fd688b);
	background-size: 40em;
	-webkit-background-clip: text;
	-webkit-text-fill-color: rgba(255, 255, 255, 0);
	animation: animated_text 6s ease-in-out infinite;
	-moz-animation: animated_text 6s ease-in-out infinite;
	-webkit-animation: animated_text 6s ease-in-out infinite;
}

@keyframes animated_text {
	0% { background-position: 0px 50%; }
	100% { background-position: 100% 50%; }
}

.marquee {
  display: block;
  left: 0;
  position: absolute;
  top: 0;
}
.box {
  display: block;
}
.logo {
  fill: #fff;
}

.bounce-box{
  width: 40vw;
  height: 20em;
  background: grey;
}


.bi-arrow-down-short{
  fill: white;
  position: absolute;
  bottom: 2em;
  animation: bounce 2s infinite;

}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {transform: translateY(-20px)}
  60% {transform: translateY(-10px)}
}