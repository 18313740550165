body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:rgb(19, 18, 20) !important;
  scroll-behavior: smooth;
}

html{
  height: 0;
}

